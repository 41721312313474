// src/components/Navbar.js
import React, { useState } from 'react';
import { Navbar, Nav, Modal, Button } from 'react-bootstrap';
import Login from './Login';
import BranchSelection from './BranchSelection';

const NavBar = ({ selectedBranch, onSearchToggle, onAdvancedSearchToggle, onBranchChange }) => {
    const [showLogin, setShowLogin] = useState(false);
    const [showBranchSelect, setShowBranchSelect] = useState(false);
    const [showLogoutConfirm, setShowLogoutConfirm] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(!!localStorage.getItem('userToken'));

    const handleLogoutConfirm = () => {
        localStorage.removeItem('userToken');
        localStorage.removeItem('userBranch');
        
        // Reset branch to previously selected branch if exists
        const previousBranch = localStorage.getItem('selectedBranch');
        if (previousBranch) {
            onBranchChange(JSON.parse(previousBranch));
        }
        
        setIsLoggedIn(false);
        setShowLogoutConfirm(false);
    };

    const handleLoginSuccess = (data) => {
        setIsLoggedIn(true);
        // Update branch to logged in user's branch
        if (data.branch) {
            const branchData = {
                id: data.branch.id,
                branch: data.branch.branch
            };
            localStorage.setItem('selectedBranch', JSON.stringify(branchData));
            localStorage.setItem('userBranch', JSON.stringify(branchData));
            onBranchChange(branchData);
        }
        setShowLogin(false);
    };

    const handleBranchChange = (branch) => {
        // Only allow branch change if user is not logged in
        if (!isLoggedIn) {
            const branchData = {
                id: branch.id,
                branch: branch.branch
            };
            onBranchChange(branchData);
            localStorage.setItem('selectedBranch', JSON.stringify(branchData));
            setShowBranchSelect(false);
        } else {
            alert("You cannot change branch while logged in. Your branch is set to your login branch.");
        }
    };

    return (
        <>
            <Navbar bg="dark" variant="dark" expand="lg">
                <Navbar.Brand href="#" style={{backgroundColor: 'white', borderRadius:'10px',padding:'5px'}}>
                    <img src="/logo.png" alt="Logo" style={{ height: '40px' }} />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="ml-auto d-flex align-items-center">
                        <Nav.Link
                            className="text-white"
                            style={{
                                backgroundColor: 'grey',
                                borderRadius: '5px',
                                padding: '5px 10px',
                                height: '36px',
                                marginRight: '10px'
                            }}
                            onClick={onSearchToggle}
                        >
                            Basic Search
                        </Nav.Link>
                        <Nav.Link
                            className="text-white"
                            style={{
                                backgroundColor: 'grey',
                                borderRadius: '5px',
                                padding: '5px 10px',
                                height: '36px',
                                marginRight: '10px'
                            }}
                            onClick={onAdvancedSearchToggle}
                        >
                            Advanced Search
                        </Nav.Link>
                        <Nav.Link
                            className="text-white"
                            style={{
                                backgroundColor: 'grey',
                                borderRadius: '5px',
                                padding: '5px 10px',
                                height: '36px',
                                marginRight: '10px'
                            }}
                            onClick={() => !isLoggedIn && setShowBranchSelect(true)}
                        >
                            Branch: {selectedBranch.branch}
                        </Nav.Link>
                        {isLoggedIn ? (
                            <Nav.Link
                                className="text-white"
                                style={{
                                    backgroundColor: 'grey',
                                    borderRadius: '5px',
                                    padding: '5px 10px',
                                    height: '36px',
                                    marginRight: '10px'
                                }}
                                onClick={() => setShowLogoutConfirm(true)}
                            >
                                Logout
                            </Nav.Link>
                        ) : (
                            <Nav.Link
                                className="text-white"
                                style={{
                                    backgroundColor: 'grey',
                                    borderRadius: '5px',
                                    padding: '5px 10px',
                                    height: '36px',
                                    marginRight: '10px'
                                }}
                                onClick={() => setShowLogin(true)}
                            >
                                Login
                            </Nav.Link>
                        )}
                    </Nav>
                </Navbar.Collapse>
            </Navbar>

            {/* Login Modal */}
            <Login 
                show={showLogin}
                onHide={() => setShowLogin(false)}
                onLogin={handleLoginSuccess}
            />

            {/* Branch Selection Modal */}
            <BranchSelection 
                show={showBranchSelect}
                onHide={() => setShowBranchSelect(false)}
                onSelectBranch={handleBranchChange}
                initialBranch={selectedBranch}
            />

            {/* Logout Confirmation Modal */}
            <Modal
                show={showLogoutConfirm}
                onHide={() => setShowLogoutConfirm(false)}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Logout</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to logout?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowLogoutConfirm(false)}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={handleLogoutConfirm}>
                        Logout
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default NavBar;
