// src/components/ProductModal.js
import React, { useState, useEffect } from 'react';
import { Modal, Button, Row, Col, Tabs, Tab, Form, Table, Alert } from 'react-bootstrap';
import '../assets/scss/ProductModal.scss';

const ProductModal = ({ show, onHide, product }) => {
    const [activeTab, setActiveTab] = useState('details');
    const [rackHistory, setRackHistory] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [rackDetails, setRackDetails] = useState({
        rack1: '',
        rack2: ''
    });
    const [updateMessage, setUpdateMessage] = useState({ type: '', message: '' });
    const [showConfirmation, setShowConfirmation] = useState(false);
    
    const isLoggedIn = !!localStorage.getItem('userToken');
    const userBranch = JSON.parse(localStorage.getItem('userBranch'));
    const userId = localStorage.getItem('userId');

    useEffect(() => {
        if (activeTab === 'rackManagement') {
            fetchRackDetails();
            fetchRackHistory();
        }
    }, [activeTab]);

    const fetchRackDetails = async () => {
        try {
            const token = localStorage.getItem('userToken');
            const response = await fetch(
                `https://live.jeyaagency.com/api/item/rack?branch_id=${userBranch.id}&item_id=${product.item_id}&token=${token}`,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                }
            );
            const result = await response.json();
            const data = result.items;
            setRackDetails({
                rack1: data.rack_no || '',
                rack2: data.rack_no2 || ''
            });
        } catch (error) {
            console.error('Failed to fetch rack details:', error);
        }
    };

    const fetchRackHistory = async () => {
        setIsLoading(true);
        try {
            const token = localStorage.getItem('userToken');
            const response = await fetch(
                `https://live.jeyaagency.com/api/item/rack/history?branch_id=${userBranch.id}&item_id=${product.item_id}&token=${token}`,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                }
            );
            const data = await response.json();
            if(data.status_code=="0"){
                setRackHistory(data.result);
            }
            else{
                setUpdateMessage({ type: 'danger', message: 'Failed to fetch rack history' });
            }
        } catch (error) {
            console.error('Failed to fetch rack history:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleRackUpdate = async () => {
        try {
            const token = localStorage.getItem('userToken');
            const response = await fetch('https://live.jeyaagency.com/api/item/rack/update', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    item_id: product.item_id,
                    user_id: userId,
                    branch_id: userBranch.id,
                    rack_no: rackDetails.rack1,
                    rack_no2: rackDetails.rack2,
                    token: token
                })
            });

            if (response.ok) {
                setUpdateMessage({ type: 'success', message: 'Rack details updated successfully' });
                setIsEditing(false);
                fetchRackHistory();
                fetchRackDetails();
            } else {
                setUpdateMessage({ type: 'danger', message: 'Failed to update rack details' });
            }
        } catch (error) {
            setUpdateMessage({ type: 'danger', message: 'Error updating rack details' });
        }
        setShowConfirmation(false);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setShowConfirmation(true);
    };

    const renderProductDetails = () => (
        <div className="product-details">
            <Row>
                <Col md={4} className="image-col">
                    <img src={product.cover_img} alt={product.item_title} className="product-image" />
                </Col>
                <Col md={8}>
                    <h4 className="heading">{product.item_title}</h4>
                    <p className="price">
                        {product.unit_price.toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'LKR'
                        })}
                    </p>
                    <Row>
                        <Col md={4} className="info-col">
                            <p><strong>Category:</strong> {product.category}</p>
                            <p><strong>ISBN:</strong> {product.isbn}</p>
                            <p><strong>EAN13:</strong> {product.ean13}</p>
                            <p><strong>Authors:</strong> {product.authors}</p>
                            <p><strong>Publisher:</strong> {product.mtanufacture}</p>
                            <p><strong>Last print:</strong> {product.last_print}</p>
                        </Col>
                        <Col md={4} className="info-col">
                            <p><strong>Department:</strong> {product.department}</p>
                            <p><strong>Weight:</strong> {product.weight} g</p>
                            <p><strong>Pack size:</strong> {product.pack_size}</p>
                            <p><strong>Is group Item:</strong> {product.is_group === 1 ? 'yes' : 'no'}</p>
                            <p><strong>Language:</strong> {product.language_text}</p>
                            <p><strong>Edition:</strong> {product.edition}</p>
                        </Col>
                    </Row>
                </Col>
            </Row>
            {product.branch_info && (
                <div className="branch-availability">
                    <Row>
                        <Col>Re-Level: {product.branch_info.reorder_level}</Col>
                        <Col>Re-Qty: {product.branch_info.reorder_qty}</Col>
                        <Col>Rack No: {product.branch_info.rack_no}</Col>
                        <Col>Rack No2: {product.branch_info.rack_no2}</Col>
                    </Row>
                </div>
            )}
            <div className="branch-availability">
                <h5>Branch Availability</h5>
                <div className="branch-list">
                    {product.branches.map((branch, index) => (
                        <div key={index} className="branch-item">
                            <p>
                                <strong>{branch.branch}:</strong> {branch.qty > 0 ? 'Available' : 'Out of Stock'}
                            </p>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );

    const renderRackManagement = () => (
        <div className="rack-management">
            {isLoggedIn ? (
                <>
                    {updateMessage.message && (
                        <Alert variant={updateMessage.type} onClose={() => setUpdateMessage({ type: '', message: '' })} dismissible>
                            {updateMessage.message}
                        </Alert>
                    )}
                    
                    <div className="rack-update-form mb-4">
                        <div className="d-flex justify-content-between align-items-center mb-3">
                            <h5>Rack Details</h5>
                            {!isEditing && (
                                <Button variant="primary" onClick={() => setIsEditing(true)}>
                                    Edit
                                </Button>
                            )}
                        </div>
                        <Form onSubmit={handleSubmit}>
                            <Row>
                                <Col md={6}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Rack No 1</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={rackDetails.rack1}
                                            onChange={(e) => setRackDetails({...rackDetails, rack1: e.target.value})}
                                            readOnly={!isEditing}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Rack No 2</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={rackDetails.rack2}
                                            onChange={(e) => setRackDetails({...rackDetails, rack2: e.target.value})}
                                            readOnly={!isEditing}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            {isEditing && (
                                <div className="d-flex gap-2">
                                    <Button type="submit" variant="success">Update</Button>
                                    <Button variant="secondary" onClick={() => {
                                        setIsEditing(false);
                                        fetchRackDetails(); // Reset to original values
                                    }}>
                                        Cancel
                                    </Button>
                                </div>
                            )}
                        </Form>
                    </div>

                    <div className="rack-history">
                        <h5>Rack Update History</h5>
                        {isLoading ? (
                            <div className="text-center">Loading history...</div>
                        ) : (
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>Rack No 1</th>
                                        <th>Rack No 2</th>
                                        <th>Updated By</th>
                                        <th>Updated On</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {rackHistory.map((history, index) => (
                                        <tr key={index}>
                                            <td>{history.rack_no}</td>
                                            <td>{history.rack_no2}</td>
                                            <td>{history.updated_by_name}</td>
                                            <td>{new Date(history.updated_at).toLocaleString()}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        )}
                    </div>

                    <Modal 
                        show={showConfirmation} 
                        onHide={() => setShowConfirmation(false)} 
                        centered
                        size="sm"  // Makes the modal smaller
                        dialogClassName="confirmation-modal" // Custom class for additional styling
                    >
                        <Modal.Header closeButton>
                            <Modal.Title style={{ fontSize: '1.1rem' }}>Confirm Update</Modal.Title>
                        </Modal.Header>
                        <Modal.Body style={{ textAlign: 'center', padding: '1rem' }}>
                            Are you sure you want to update the rack details?
                        </Modal.Body>
                        <Modal.Footer style={{ justifyContent: 'center', padding: '0.75rem' }}>
                            <Button variant="secondary" size="sm" onClick={() => setShowConfirmation(false)}>
                                Cancel
                            </Button>
                            <Button variant="primary" size="sm" onClick={handleRackUpdate}>
                                Confirm
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </>
            ) : (
                <Alert variant="info">
                    You must be logged in and assigned to this branch to manage rack details.
                </Alert>
            )}
        </div>
    );

    return (
        <Modal show={show} onHide={onHide} dialogClassName="modal-large">
            <Modal.Header closeButton className="modal-header">
                <Modal.Title>Product Details</Modal.Title>
            </Modal.Header>
            <Modal.Body className="modal-body">
                <Tabs
                    activeKey={activeTab}
                    onSelect={(k) => setActiveTab(k)}
                    className="mb-3"
                >
                    <Tab eventKey="details" title="Product Details">
                        {renderProductDetails()}
                    </Tab>
                    <Tab eventKey="rackManagement" title="Rack Management">
                        {renderRackManagement()}
                    </Tab>
                </Tabs>
            </Modal.Body>
            <Modal.Footer className="modal-footer">
                <Button variant="secondary" onClick={onHide}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ProductModal;