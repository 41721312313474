import React, { useState, useEffect } from 'react';
import { Modal, Form, Button, Alert } from 'react-bootstrap';

const Login = ({ show, onHide, onLogin }) => {
    const [branches, setBranches] = useState([]);
    const [loginError, setLoginError] = useState('');

    useEffect(() => {
        const fetchBranches = async () => {
            try {
                const response = await fetch("https://live.jeyaagency.com/api/global/branch/list?");
                const data = await response.json();
                setBranches(data.branches);
            } catch (error) {
                console.error('Error fetching branches:', error);
            }
        };

        if (show) {
            fetchBranches();
        }
    }, [show]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoginError('');
        
        const { username, password, branch } = e.target.elements;
        
        try {
            const response = await fetch('https://live.jeyaagency.com/api/login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email: username.value,
                    password: password.value,
                    branch_id: branch.value
                })
            });

            if (response.ok) {
                const data = await response.json();
                if(data.status_code=="0"){
                    localStorage.setItem('userToken', data.token);
                    
                    // Store user's branch
                    const selectedBranch = branches.find(b => b.id === parseInt(branch.value));
                    const branchData = {
                        id: selectedBranch.id,
                        branch: selectedBranch.branch
                    };
                    
                    // Update both selectedBranch and userBranch in localStorage
                    localStorage.setItem('selectedBranch', JSON.stringify(branchData));
                    localStorage.setItem('userBranch', JSON.stringify(branchData));
                    localStorage.setItem('userId', data.user_details.id);
                    
                    onLogin({
                        ...data,
                        branch: branchData
                    });
                }
                else{
                    setLoginError(data.message);
                }
            } else {
                setLoginError('Invalid credentials or branch selection');
            }
        } catch (error) {
            setLoginError('Login failed. Please try again.');
            console.error('Login failed:', error);
        }
    };

    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>Login</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {loginError && (
                    <Alert variant="danger">{loginError}</Alert>
                )}
                <Form onSubmit={handleSubmit}>
                    <Form.Group className="mb-3">
                        <Form.Label>Username</Form.Label>
                        <Form.Control type="text" name="username" required />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Password</Form.Label>
                        <Form.Control type="password" name="password" required />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Branch</Form.Label>
                        <Form.Select name="branch" required>
                            <option value="">Select Branch</option>
                            {branches.map((branch) => (
                                <option key={branch.id} value={branch.id}>
                                    {branch.branch}
                                </option>
                            ))}
                        </Form.Select>
                    </Form.Group>
                    <Button type="submit" className="w-100">Login</Button>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default Login; 