import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

const BranchSelection = ({ show, onHide, onSelectBranch, initialBranch = null }) => {
    const [branches, setBranches] = useState([]);
    const [selectedBranchId, setSelectedBranchId] = useState(initialBranch?.id || '');

    useEffect(() => {
        const fetchBranches = async () => {
            try {
                const response = await fetch("https://live.jeyaagency.com/api/global/branch/list?");
                const data = await response.json();
                setBranches(data.branches);
            } catch (error) {
                console.error('Error fetching branches:', error);
            }
        };

        fetchBranches();
    }, []);

    useEffect(() => {
        if (initialBranch) {
            setSelectedBranchId(initialBranch.id);
        }
    }, [initialBranch]);

    const handleBranchSelect = (e) => {
        e.preventDefault();
        if (!selectedBranchId) {
            alert('Please select a branch to continue');
            return;
        }

        const selectedBranch = branches.find(b => b.id === parseInt(selectedBranchId));
        const branchData = {
            id: selectedBranch.id,
            branch: selectedBranch.branch
        };
        
        // Update selectedBranch in localStorage
        localStorage.setItem('selectedBranch', JSON.stringify(branchData));
        
        // If user is logged in, update userBranch as well
        if (localStorage.getItem('userToken')) {
            localStorage.setItem('userBranch', JSON.stringify(branchData));
        }
        
        onSelectBranch(branchData);
        if (onHide) onHide();
    };

    return (
        <Modal 
            show={show} 
            onHide={onHide}
            backdrop={!initialBranch ? "static" : true}
            keyboard={!!initialBranch}
        >
            <Modal.Header closeButton={!!initialBranch}>
                <Modal.Title>Select Branch</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleBranchSelect}>
                    <Form.Group controlId="branch">
                        <Form.Label>Branch</Form.Label>
                        <Form.Control 
                            as="select" 
                            value={selectedBranchId}
                            onChange={(e) => setSelectedBranchId(e.target.value)}
                            required
                        >
                            <option value="">Select a branch</option>
                            {branches.map((branch) => (
                                <option key={branch.id} value={branch.id}>
                                    {branch.branch}
                                </option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                    <br />
                    <Button 
                        type="submit" 
                        className="w-100"
                        disabled={!selectedBranchId}
                    >
                        Select Branch
                    </Button>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default BranchSelection;
