import React, { useEffect, useState } from 'react';
import NavBar from './components/Navbar';
import SearchBar from './components/SearchBar';
import AdvancedSearch from './components/AdvancedSearch';
import ProductTable from './components/ProductTable';
import ProductGrid from './components/ProductGrid';
import ProductModal from './components/ProductModal';
import Pagination from './components/Pagination';
import BranchSelection from './components/BranchSelection';
import { Container, Row, Col } from 'react-bootstrap';
import Loader from './components/Loader';
import './assets/scss/custom-bootstrap.scss';

const App = () => {
    const [showAdvancedSearch, setShowAdvancedSearch] = useState(false);
    const [view, setView] = useState('grid');
    const [products, setProducts] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [modalShow, setModalShow] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [searchParams, setSearchParams] = useState({});
    const [selectedBranch, setSelectedBranch] = useState(null);
    const [showBranchSelect, setShowBranchSelect] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const storedBranch = localStorage.getItem('selectedBranch');
        if (storedBranch) {
            setSelectedBranch(JSON.parse(storedBranch));
        } else {
            setShowBranchSelect(true);
        }
    }, []);

    useEffect(() => {
        if (Object.keys(searchParams).length > 0) {
            handleSearch(searchParams, currentPage);
        }
    }, [currentPage, searchParams]);

    const handleSearch = async (params, page = 1) => {
        setLoading(true);
        const queryString = new URLSearchParams(params).toString();
        const response = await fetch(`https://live.jeyaagency.com/api/global/search?${queryString}&page=${page}`);
        const data = await response.json();

        setProducts(data.items.data || []);
        setCurrentPage(data.items.current_page);
        setTotalPages(data.items.last_page);
        setLoading(false);
    };

    const handleShowDetails = (product) => {
        const getStock = async () => {
            const response = await fetch(`https://live.jeyaagency.com/api/global/search/stock?id=${product.item_id}&branch_id=${selectedBranch.id}`);
            const data = await response.json();
            try {
                product.branches = data.branch_stock;
                product.branch_info = data.branch_info;
                setSelectedProduct(product);
                setModalShow(true);
            } catch(error) {
                console.log(error);
            }
        };
        getStock();
    };

    const handleSearchSubmit = (params) => {
        setSearchParams(params);
        setCurrentPage(1);  // Reset to first page on new search
        handleSearch(params, 1);
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handleBranchChange = (branch) => {
        const branchData = {
            id: branch.id,
            branch: branch.branch
        };
        setSelectedBranch(branchData);
        localStorage.setItem('selectedBranch', JSON.stringify(branchData));
        
        // If user is logged in, update their branch as well
        if (localStorage.getItem('userToken')) {
            localStorage.setItem('userBranch', JSON.stringify(branchData));
        }
        
        // Refresh product data with new branch
        if (Object.keys(searchParams).length > 0) {
            handleSearch(searchParams, currentPage);
        }
    };

    return (
        <div>
            {!selectedBranch && (
                <BranchSelection 
                    show={showBranchSelect}
                    onSelectBranch={handleBranchChange}
                />
            )}
            {selectedBranch && (
                <>
                    <NavBar 
                        selectedBranch={selectedBranch}
                        onSearchToggle={() => setShowAdvancedSearch(false)}
                        onAdvancedSearchToggle={() => setShowAdvancedSearch(true)}
                        onBranchChange={handleBranchChange}
                    />
                    <Container className="mt-4">
                        <Row>
                            <Col className="text-center">
                                {showAdvancedSearch ? (
                                    <AdvancedSearch onSearch={handleSearchSubmit} />
                                ) : (
                                    <SearchBar onSearch={handleSearchSubmit} />
                                )}
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col className="d-flex justify-content-end">
                                <div className="btn-group" role="group" aria-label="View Options">
                                    <button
                                        type="button"
                                        className={`btn btn-outline-secondary ${view === 'table' ? 'active' : ''}`}
                                        onClick={() => setView('table')}
                                    >
                                        <i className="bi bi-table"></i>
                                    </button>
                                    <button
                                        type="button"
                                        className={`btn btn-outline-secondary ${view === 'grid' ? 'active' : ''}`}
                                        onClick={() => setView('grid')}
                                    >
                                        <i className="bi bi-grid"></i>
                                    </button>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                {loading ? (
                                    <Loader />
                                ) : (
                                    products.length > 0 ? (
                                        view === 'table' ? (
                                            <ProductTable products={products} onShowDetails={handleShowDetails} />
                                        ) : (
                                            <ProductGrid products={products} onShowDetails={handleShowDetails} />
                                        )
                                    ) : (
                                        <div className="alert alert-danger" role="alert">
                                            No products available to list, please enter keywords and search
                                        </div>
                                    )
                                )}
                            </Col>
                        </Row>
                        {products.length > 0 && (
                            <Row>
                                <Col className="d-flex justify-content-center">
                                    <Pagination
                                        currentPage={currentPage}
                                        totalPages={totalPages}
                                        onPageChange={handlePageChange}
                                    />
                                </Col>
                            </Row>
                        )}
                    </Container>
                    {selectedProduct && (
                        <ProductModal
                            show={modalShow}
                            onHide={() => setModalShow(false)}
                            product={selectedProduct}
                        />
                    )}
                </>
            )}
        </div>
    );
};

export default App;